import axios from 'axios';
import toast from 'react-hot-toast';
import { API_URL } from '../constants';

const dataOnly = (response) => response.data;
       

const createApiInstance = (baseURL) => {

    const instance = axios.create({
        baseURL
    });


    instance.interceptors.response.use(dataOnly, (error) => {
        console.log('error', error);
        if (error.response.status === 400) {

            // errors is an object, each key is an array of strings
            const errors = Object.keys(error.response.data.errors)
                .reduce((acc, key) => acc.concat(error.response.data.errors[key]), []);

            toast.error(error.response.data.title, errors.join('\r\n'), { timeOut: 5000 });
        } else {
            
            const msg = error.response.data && error.response.data.title;
            toast.error(msg || (error.response.status === 401 ? 'Not Authorized' : 'Error while contacting the server'));
        }

        return Promise.reject(error);

    });


    return instance;
}

export default createApiInstance(API_URL);

