import React, { useEffect, useState } from 'react';
import { useFormContext, useFieldArray } from "react-hook-form";
import PageContainer from './shared/PageContainer';
import ApplicantInfo from './shared/ApplicantInfo';
import PaymentInfo from './shared/PaymentInfo';
import ApplicationForm, { FileUploadInput, TrueFalseRadioButtons } from './shared/ApplicationForm';
import { AiFillCloseCircle, AiFillFileAdd } from 'react-icons/ai';

import { TextAreaField, TextField } from '../shared/FormElements';

export const RealEstate = () => {

    return <PageContainer title="Connecticut Real Estate Salesperson Application">
        <ApplicationForm clientAppTypeId={1}>
            <ApplicantInfo>
                <RealEstateQuestions />
            </ApplicantInfo>
            <PaymentInfo />
            <FileUploads />
            <Acknowledgement />
        </ApplicationForm>
    </PageContainer>
};

const Acknowledgement = () => {

    const { register } = useFormContext();

    return <>   
        <h4 className="mt-4 pt-3 pb-3">Acknowledgement</h4>
        <div className="border rounded p-4">
            <hr className="mt-0 mb-3" style={{ borderTop: '1px dashed #ccc' }} />
            <div className="row">
                <div className="col mb-3">
                    <div className="form-check">
                        <input className="form-check-input mt-2" type="checkbox" value={true} {...register("signStatement")} id="signStatement" />
                        <label className="form-check-label required ps-2" htmlFor="signStatement">
                            I, being duly sworn according to law, hereby affirm that the answers given in this application are true to the best of my knowledge and belief and that this application is made for the sole purpose of obtaining a Real Estate Salesperson license.
                        </label>
                    </div>
                </div>
            </div>
        </div>

    </>
}

const FileUploads = () => {

    const { register, control } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: "fileUploads"
    });

    return <>
        <h4 className="mt-4 pt-3 pb-3">Attachments</h4>
        <div className="border rounded p-4">
            <hr className="mt-0 mb-3" style={{ borderTop: '1px dashed #ccc' }} />
            <div className="row">
                <div className="col mb-3">
                    <ul className="list-unstyled">
                        {fields.map((item, index) => (
                            <li key={item.id}>
                                <div className="row mb-2">
                                    <div className="col">
                                        <FileUploadInput
                                            register={register}
                                            control={control}
                                            name={`fileUploads.${index}`}
                                            ariaDescribedby={`fileUploads.${index}`}
                                        />
                                    </div>
                                    <div className="col-1">
                                        <button type="button" className="btn text-secondary" aria-label="Remove file" onClick={() => remove(index)}>
                                            <AiFillCloseCircle size={25} />
                                        </button>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <button type="button" className="btn" aria-label="Add file" onClick={() => append({})}>
                        <AiFillFileAdd /> Add File
                    </button>
                </div>
            </div>
        </div>

    </>
}

const RealEstateQuestions = () => {

    const { watch, control, register, formState: { errors } } = useFormContext();
    const [watchFelony, watchSuspended, watchConvictedOfCrime, watchLicenseTypeId] =
        watch(["convictedOfFelony", "licenseSuspenedRevoked", "convictedOfCrime", "licenseTypeId"]); 

    const [licenseTypeId, setLicenseTypeId] = useState(parseInt(watchLicenseTypeId));

    useEffect(() => {
        setLicenseTypeId(parseInt(watchLicenseTypeId));
    }, [watchLicenseTypeId, setLicenseTypeId]);


    return <>
        <hr className="mt-0 mb-3" style={{ borderTop: '1px dashed #ccc' }} />
        <div className="row">
            <div className="col">
                {licenseTypeId === 1 && <ol>

                    <li>This application must be completed and signed. The Federal Privacy Act of 1974 requires that you be notified that disclosure of your Social Security Number is required pursuant to CGS 17b-137a. If you choose not to disclose your Social Security Number, your application may not be processed.</li>
                    <li>The original 60-hour Real Estate Principles & Practices in Real Estate course completion certificate must be submitted with this completed application</li>
                    <li>An application fee must accompany this submission and is non-refundable.</li>
                    <li>After this application is reviewed and approved, you will receive an Examination Eligibility email from PSI with instructions to register and schedule the examination. Please note that the examination fee will be due at the time you schedule the examination with PSI.</li>
                </ol>}

                {licenseTypeId === 2 && <ol>

                    <li>This application must be fully completed. The Federal Privacy Act of 1974 requires that you be notified that disclosure of your Social Security Number is required pursuant to CGS 17b-137a. If you choose not to disclose your Social Security Number, your application may not be processed.</li>
                    <li>Effective January 1, 2014, the only acceptable Principles & Practices of Real Estate course completion certificate will be that of an approved 60-hour course. (Two 30-hour courses are no longer accepted).</li>
                    <li> Provide original certificates for the following courses: 60-hour Real Estate Principles & Practices and 15-hour Legal Compliance and 15-hour Broker Principles & Practices and 2-15- or 1-30-hour pre-license real estate elective(s).</li>
                    <li>Provide proof of no less than1,500 hours of active salesperson experience and at least 4 real estate transactions (legal transfer of property or lease agreement executed between a landlord and tenant) closed in the three (3) previous years (use attached form)</li>
                    <li>An application fee must accompany this submission and is non-refundable.</li>
                    <li>After this application is reviewed and approved, you will receive an Examination Eligibility email from PSI with instructions to register and schedule the examination. The examination fee will be due at the time you schedule the examination with PSI.</li>
                </ol>}
            </div>
        </div>
        {licenseTypeId === 2 && <><hr className="mt-0 mb-3" style={{ borderTop: '1px dashed #ccc' }} />
            <div className="row">
                <div className="col-12 mb-3">
                    <p className="required form-label">I acknowledge that I have completed the required coursework listed above and have been activily engaged for at least three (3) years as a licensed real estate salesperson under the supervision of a licensed real estate broker in this state</p>
                    <TrueFalseRadioButtons name="completedCoursework" control={control} />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 col-lg-5 mb-3">
                    <p className="required">Please provide your real estate salesperson license number</p>
                    <TextField label="RES #" name="licenseNumber" register={register} control={control} errors={errors} />
                </div>
            </div>

        </>}
        <hr className="mt-0 mb-3" style={{ borderTop: '1px dashed #ccc' }} />
        <div className="row">
            <div className="col-12 mb-3">
                <p className="required form-label">Have you ever been convicted of a felony?</p>
                <TrueFalseRadioButtons name="convictedOfFelony" control={control} />
            </div>
            {watchFelony && <div className="col-12 mb-4">
                <label htmlFor="criminalWorksheetUpload" className="form-label required">Please attach your completed Criminal Conviction Application Worksheet.</label>
                <FileUploadInput register={register} control={control} name="felonyFile" ariaDescribedby="criminalWorksheetHelp" />
                <div id="criminalWorksheetHelp" className="form-text">Download @ <a href="https://portal.ct.gov/dcp" target="_blank" rel="noreferrer" className="text-decoration-underline">https://portal.ct.gov/dcp</a></div>
            </div>}
        </div>
        <hr className="mt-0 mb-3" style={{ borderTop: '1px dashed #ccc' }} />
        <div className="row">
            <div className="col-12 mb-3">
                <p className="required form-label">Have you ever been convicted of a felony crime or been convicted of a crime including, but not limited to, forgery, embezzlement,
                    obtaining money under false pretenses, extortion, criminal conspiracy to defraud, or any like offenses? YES NO If Yes, attach
                    a statement providing the date(s) and nature of conviction(s), where the cases were decided, and a description of the circumstances.</p>
                <TrueFalseRadioButtons name="convictedOfCrime" control={control} />
            </div>
            {watchConvictedOfCrime && <div className="col-12 mb-4">
                <label htmlFor="criminalWorksheet" className="form-label required">Please attach your completed Criminal Conviction Application Worksheet.</label>
                <FileUploadInput register={register} control={control} name="crimeFile" ariaDescribedby="criminalWorksheet" />
            </div>}
        </div>
        <hr className="mt-0 mb-3" style={{ borderTop: '1px dashed #ccc' }} />
        <div className="row">
            <div className="col-12 mb-3">
                <p className="required form-label">Have you ever had a real estate license refused, suspended or revoked in any State?</p>
                <TrueFalseRadioButtons name="licenseSuspenedRevoked" control={control} />
            </div>
            {watchSuspended && <div className="col-12 mb-4">
                <TextAreaField label="Please list details" register={register} errors={errors} name="revokedDetails" labelClassName="required" />
            </div>}
        </div>

    </>

}


export default RealEstate;