import React, { useEffect, useState} from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";

import { REQUEST_SCOPES } from '../../utils/authConfig';

import Loading from '../shared/Loading';

const Authorizer = ({ children }) => {

    const [loading, setLoading] = useState(true);
    const { instance, inProgress, accounts } = useMsal();

    useEffect(() => {
        if (loading && inProgress === InteractionStatus.None) {

            if (accounts.length > 0) {
                // Acquire an access token
                instance.acquireTokenSilent({ ...REQUEST_SCOPES, account: accounts[0] }).then(() => {
                    setLoading(false);
                }).catch(async (e) => {
                    // Catch interaction_required errors and call interactive method to resolve
                    if (e instanceof InteractionRequiredAuthError) {
                        await instance.acquireTokenRedirect({ ...REQUEST_SCOPES, account: accounts[0] });
                    }

                    throw e;
                });
            } else {
                instance.loginRedirect({ ...REQUEST_SCOPES });
            }
        } 
    }, [inProgress, accounts, instance, loading]);


    return <>
        <AuthenticatedTemplate>
                {children}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <Loading />
        </UnauthenticatedTemplate>
      </>
}

export default Authorizer;