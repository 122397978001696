import axios from 'axios';
import toast from 'react-hot-toast';
import { API_URL } from '../constants';
import { msalConfig, TOKEN_REQUEST, REQUEST_SCOPES } from '../utils/authConfig';

import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";


const dataOnly = (response) => response.data;
       
const pca = new PublicClientApplication(msalConfig);


const createApiInstance = (baseURL, tokenRequest) => {

    const instance = axios.create({
        baseURL
    });

    instance.interceptors.request.use(async (config) => {

        let accessToken;
        const accounts = pca.getAllAccounts();
        const account = accounts[0] || {};
        // Request for Access Token
        try {
            let response = await pca.acquireTokenSilent({ ...tokenRequest, account: account });
            accessToken = response.accessToken;
        } catch (error) {

            // call acquireTokenPopup in case of acquireTokenSilent failure
            // due to consent or interaction required
            if (error instanceof InteractionRequiredAuthError) {
                let response = await pca.acquireTokenPopup({ ...REQUEST_SCOPES, account: account });
                accessToken = response.accessToken;

            }
        }

        const bearer = `Bearer ${accessToken}`;
        config.headers.Authorization = bearer;
        return config;
    });

    instance.interceptors.response.use(dataOnly, (error) => {
        console.log('error', error);
        if (error.response.status === 400) {

            // errors is an object, each key is an array of strings
            const errors = Object.keys(error.response.data.errors)
                .reduce((acc, key) => acc.concat(error.response.data.errors[key]), []);

            toast.error(error.response.data.title, errors.join('\r\n'), { timeOut: 5000 });
        } else {
            
            const msg = error.response.data && error.response.data.title;
            toast.error(msg || (error.response.status === 401 ? 'Not Authorized' : 'Error while contacting the server'));
        }

        return Promise.reject(error);

    });


    return instance;
}




export const download = (url, fileName) => {

    const instance = axios.create();

    instance.interceptors.request.use(async (config) => {

        let accessToken;
        const accounts = pca.getAllAccounts();
        const account = accounts[0] || {};
        // Request for Access Token
        try {
            let response = await pca.acquireTokenSilent({ ...TOKEN_REQUEST, account: account });
            accessToken = response.accessToken;
        } catch (error) {

            // call acquireTokenPopup in case of acquireTokenSilent failure
            // due to consent or interaction required
            if (error instanceof InteractionRequiredAuthError) {
                let response = await pca.acquireTokenPopup({ ...REQUEST_SCOPES, account: account });
                accessToken = response.accessToken;

            }
        }

        const bearer = `Bearer ${accessToken}`;
        config.headers.Authorization = bearer;
        return config;
    });

    instance
        .request({
            url,
            method: 'GET',
            responseType: 'blob', //important
        })
        .then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch(error => {

            if (error.response.status === 404) {

                toast.error('Unable to find file', 'File does not exist.  Please call your coordinator', { timeOut: 5000 });
            } else {

                const msg = error.response.data && error.response.data.message;
                toast.error('Error', msg || (error.response.status === 401 ? 'Not Authorized' : 'Error while contacting the server'));
            }



        });

}

export default createApiInstance(API_URL, TOKEN_REQUEST);
