import { DateTime } from "luxon";
import { PatternFormat } from 'react-number-format';


export const FormatPhone = ({ phone }) => {

    return phone ? <PatternFormat format="###-###-####" value={phone} displayType="text" /> : "";
    
}


export const formatDateFromISO = (dt) => dt ? DateTime.fromISO(dt).toFormat('M-d-yyyy') : '';

export const formatDateFromISOtoLocal = (dt) => dt ? DateTime.fromISO(dt, { zone: 'UTC' }).toLocal().toFormat('f') : '';
