import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

export const Home = () => {

	return (
    <>
    <div className="container py-5 v-center">
      <div className="row py-lg-4 px-3 px-md-0 justify-content-center">
        <div className="col-md-6 col-xl-4 mb-3 mb-lg-0 pe-md-4">
          <Link to="/real-estate">
            <div className="shadow-sm p-5 rounded-3 text-center h-100 app-btn">
              <FaHome style={{fontSize:'3.4rem'}} />
              <h4 className="mt-4 mb-0">Connecticut Real Estate</h4>
            </div>
          </Link>
        </div>
      </div>
    </div>
    </>
	);
};

export default Home;