import classNames from 'classnames';

const Loading = ({ logo = false }) => {
    return (
        <div className="w-100 h-100 pb-5">
            <div className="row d-flex justify-content-center v-center m-0">
                {logo && (
                    <div className="col-12 text-center mb-4">
                        <img src="/PSI_Logo.svg" style={{ height: '50px' }} alt="PSI Testing Excellence" />
                    </div>
                )}
                <Spinner className="col-12 text-primary" style={{ width: '3rem', height: '3rem' }} />
                <div className="col-12 text-center text-primary mt-4">
                    <p>Loading...</p>
                </div>
            </div>
        </div>
    );
};

export const Spinner = ({ className, ...props }) => (
    <div className={classNames('spinner-border', className)} role="status" {...props}>
        <span className="visually-hidden">Loading...</span>
    </div>
);

export const SpinnerSm = ({ className, ...props }) => (
    <Spinner className={classNames('spinner-border-sm', className)} {...props} />
);

export default Loading;