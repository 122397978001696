import React, { useState } from 'react';
import classNames from 'classnames';

const Footer = ({ overlay }) => {

    const [year] = useState(new Date().getFullYear())

  return (
      <div className="container-fluid" style={{fontSize:'.85em'}}>
          <div className={classNames('row', overlay ? 'text-white' : '')}>
            <div className="col-12 text-center">
                  <p>© {year} PSI Services, LLC.</p>
            </div>
        </div>
      </div>
  );
}

export default Footer;