import React, { useState, useCallback, useRef, useImperativeHandle, useEffect, Suspense }  from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    getFilteredRowModel,
    flexRender,
    getExpandedRowModel
} from '@tanstack/react-table';
import classNames from 'classnames';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { MdChevronLeft, MdChevronRight, MdFirstPage, MdLastPage, MdClose } from 'react-icons/md';
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';
import Loading from './Loading';

// setGlobalFilter needs passed stable (useCallback)
export const GlobalSearch = ({ globalFilter, setGlobalFilter }) => {
    const [value, setValue] = useState(globalFilter || '');

    useEffect(() => {
        const timeoutId = setTimeout(() => setGlobalFilter((value || '').trim() || undefined), 200);
        return () => clearTimeout(timeoutId);
    }, [value, setGlobalFilter]);

    return (
        <div className="input-group mb-3">
            <input
                type="text"
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                value={value || ''}
                onChange={(e) => setValue(e.target.value)}
            />
            <button
                className="btn btn-outline-primary"
                type="button"
                disabled={!value}
                onClick={() => {
                    setValue('');
                    setGlobalFilter(undefined);
                }}
            >
                <MdClose />
            </button>
        </div>
    );
}

export const TableWithGlobalSearch = ({ title, extraBtn, ...props }) => {
    const tableContextRef = useRef();
    const handleSetGlobalFilter = useCallback((val) => tableContextRef.current.setGlobalFilter(val), []);

    return     <>
      <div className="row my-3">
        <div className="col">
          <h3>{title}</h3>
        </div>
        <div className="col-md-6 col-lg-4">
         <GlobalSearch globalFilter={props.initialState?.globalFilter} setGlobalFilter={handleSetGlobalFilter} />
        </div>
        {extraBtn && <div className="col-auto">
          {extraBtn}
        </div>}
      </div>
      <div className="row">
        <div className="col-12 mb-3">
          <Table withGlobalFilter contextRef={tableContextRef} {...props} />
        </div>
      </div>
    </>
}

export const TableWithTitle = ({ title, extraBtn, ...props }) => {

    return <>
        <div className="row my-3">
            <div className="col">
                <h3>{title}</h3>
            </div>
            {extraBtn && <div className="col-auto">
                {extraBtn}
            </div>}
        </div>
        <div className="row">
            <div className="col-12 mb-3">
                <Table {...props}  />
            </div>
        </div>
    </>
}

const Table = ({
    columns,
    data,
    initialState,
    meta, // for cell renders, ends up on table.options.meta and will be spread into expandedComponent
    tableClassName,
    contextRef,
    withSortBy = false,
    withPagination = false,
    withGlobalFilter = false,
    withExpanded = false,
    expandedComponent
}) => {

    const table = useReactTable({
        columns,
        data,
        initialState,
        meta,
        getCoreRowModel: getCoreRowModel(),
        enableSorting: withSortBy,
        getSortedRowModel: withSortBy ? getSortedRowModel() : null,
        getPaginationRowModel: withPagination ? getPaginationRowModel() : null,
        enableGlobalFilter: withGlobalFilter,
        getFilteredRowModel: withGlobalFilter ? getFilteredRowModel() : null,
        enableExpanding: withExpanded,
        getRowCanExpand: () => withExpanded, // normally looks like it checks the subrows
        getExpandedRowModel: withExpanded ? getExpandedRowModel() : null,
    });

    useImperativeHandle(
        contextRef,
        () => ({
            setGlobalFilter: table.setGlobalFilter,
        }),
        [table]
    );

    return (
        <div className="react-table">
            <CoreTable table={table} tableClassName={tableClassName} expandedComponent={expandedComponent} />
            {withPagination && <Pagination table={table} />}
        </div>
    );
}

const CoreTable = ({ table, tableClassName, expandedComponent: ExpandedComponent }) => {

    return (
        <div className="table-responsive">
            <table className={classNames('table', tableClassName)}>
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    onClick={header.column.getToggleSortingHandler()}
                                    style={
                                        header.column.getCanSort()
                                            ? { cursor: 'pointer', ...header.column.columnDef.headerProps?.style }
                                            : header.column.columnDef.headerProps?.style
                                    }
                                >
                                    {!header.isPlaceholder && (
                                        <>
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {header.column.getIsSorted() && (
                                                <span className="ms-1">
                                                    {header.column.getIsSorted() === 'desc' ? <AiFillCaretDown /> : <AiFillCaretUp />}
                                                </span>
                                            )}
                                        </>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) =>
                        ExpandedComponent ? (
                            <React.Fragment key={row.id}>
                                <tr className="expander-row">
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td
                                                key={cell.id}
                                                className={classNames({
                                                    'expanded-cell': row.getIsExpanded(),
                                                })}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        );
                                    })}
                                </tr>
                                {row.getIsExpanded() && (
                                    <tr className="p-0">
                                        <td colSpan={row.getVisibleCells().length} className="p-0">
                                            <Suspense fallback={<Loading />}>
                                                <ExpandedComponent row={row} {...table.options.meta} />
                                            </Suspense>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ) : (
                            <tr key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                                ))}
                            </tr>
                        )
                    )}
                </tbody>
            </table>
        </div>
    );
}

function Pagination({ table }) {
  return (
    <div className="pagination row justify-content-end mt-3 text-gray-600">
      <div className="col-12 col-sm-auto">
        <div className="row">
          <div className="col-auto d-none d-sm-block">
            Rows per page:
            <select
              className="minimal-select ms-2"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="col-auto px-4 d-none d-sm-block">
            Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
          </div>
          <div className="col-12 col-sm-auto ps-sm-3">
            <div className="row g-2">
              <div className="col text-center">
                <button
                  className="btn-pagination"
                  onClick={() => table.setPageIndex(0)}
                  disabled={!table.getCanPreviousPage()}
                >
                  {<MdFirstPage />}
                </button>
              </div>
              <div className="col text-center">
                <button
                  className="btn-pagination"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  {<MdChevronLeft />}
                </button>
              </div>
              <div className="col-auto d-sm-none">
                <select
                  className="minimal-select ms-2"
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                  }}
                >
                  {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col text-center">
                <button className="btn-pagination" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                  {<MdChevronRight />}
                </button>
              </div>
              <div className="col text-center">
                <button
                  className="btn-pagination"
                  onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                  disabled={!table.getCanNextPage()}
                >
                  {<MdLastPage />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const expanderColumn = {
    header: '',
    id: 'expander',
    cell: ({ row }) => (
        <div onClick={row.getToggleExpandedHandler()} style={{ cursor: 'pointer' }}>
            {row.getIsExpanded() ? <IoIosArrowDown size={24} /> : <IoIosArrowForward size={24} />}
        </div>
    ),
    headerProps: {
        style: {
            width: '30px',
        },
    },
};

export default Table;
