import React from 'react';
import { NumberFormatField, TextField, SelectField } from '../../shared/FormElements';
import { STATES } from '../../../constants';
import { useFormContext } from "react-hook-form";

export const PaymentInfo = () => {

    const { register, control, watch, formState: { errors } } = useFormContext();

    const fee = watch('fee');


    return (
        <>
            <h4 className="mt-4 pt-3 pb-3">Payment Information</h4>
            <div className="border rounded p-4">
                <div className="row">
                    <div className="col-12 mb-4">
                        <h5>Total Fees: ${fee}</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-4 mb-4">
                        <TextField name="cardholder" label="Cardholder Name" register={register} errors={errors} labelClassName="required" />
                    </div>
                    <div className="col-md-6 col-lg-4 mb-4">
                        <NumberFormatField
                            name="cardNumber"
                            label="Credit Card Number"
                            errors={errors}
                            valueIsNumericString
                            labelClassName="required"
                            format="#### #### #### ####"
                            control={control}
                        />
                    </div>
                    <div className="col-md-3 col-lg-2 mb-4">
                        <NumberFormatField
                            name="expiration"
                            label="Expiration"
                            valueIsNumericString
                            errors={errors}
                            labelClassName="required"
                            format="##/##"
                            control={control}
                        />
                    </div>
                    <div className="col-md-3 col-lg-2 mb-4">
                        <NumberFormatField
                            name="cvv"
                            label="CVV"
                            errors={errors}
                            labelClassName="required"
                            format="####"
                            control={control}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 col-lg-5 mb-4">
                        <TextField name="billingAddress" label="Billing Street Address" register={register} errors={errors} labelClassName="required" />
                    </div>
                    <div className="col-sm-6 col-lg-3 mb-4">
                        <TextField name="billingCity" label="City" register={register} errors={errors} labelClassName="required" />
                    </div>
                    <div className="col-sm-6 col-lg-2 mb-4">
                        <SelectField name="billingState" label="State" register={register} errors={errors} labelClassName="required">
                            <option value="">-- Choose --</option>
                            {STATES.map(x => <option key={`ms-${x.code}`} value={x.code} >{x.name}</option>)}
                        </SelectField>
                    </div>
                    <div className="col-sm-6 col-lg-2 mb-4">
                        <NumberFormatField
                            name="billingZip"
                            label="Zip/Postal Code"
                            errors={errors}
                            valueIsNumericString
                            labelClassName="required"
                            format="#####"
                            control={control}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentInfo;

