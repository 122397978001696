import axios from './authHandler';

export const getApplications = (includeExported) => {
    return axios.get(`/admin/applications?includeExported=${includeExported}`);
};

export const getApplication = (applicationId) => {
    return axios.get(`/admin/applications/${applicationId}`);
};

export const markExported = (applicationId) => {
    return axios.patch(`/admin/applications/${applicationId}/export`);
};
