import axios from './handler.js'


export const saveApplicaton = (data) => {
    const formData = new FormData();

    console.log(data);

    Object.keys(data).forEach((val, index) => {
        if (val === 'currentLicenseUpload' || val === 'felonyFile' || val === 'crimeFile') {
            formData.append('files', data[val][0]);
        }
        else if (val === 'fileUploads')
            data[val].forEach(t => {
                formData.append('files', t[0]);
            });
        else {
           formData.append(val, data[val]);
        }
    })

    return axios.request({
        method: 'POST',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        url: '/application'
    });
}

export const getLicenseTypes = (clientAppTypeId) => {
    return axios.get(`/application/licenseTypes/${clientAppTypeId}`);
};

export const getExams = () => {
    return axios.get(`/application/exams`);
};

