import React from "react";
import { Routes, Route } from "react-router-dom";
import "./assets/scss/main.scss";
import toast, { Toaster, ToastBar } from 'react-hot-toast';
import { BsX } from 'react-icons/bs';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import RealEstate from "./components/application/RealEstate";
import Admin from "./components/admin/Admin";
import ThankYou from "./components/ThankYou";


function App() {
    return (
        <div className="App wrapper">
            <div className="flex-header shadow-sm z-index-2">
                <Header />
            </div>
            <div className="flex-content">
                <Routes>
                    <Route path="/*" element={<Home />} />
                    <Route path="/real-estate" element={<RealEstate />} />
                    <Route path="/admin/*" element={<Admin />} />
                    <Route path="/thank-you" element={<ThankYou />} />
                </Routes>
            </div>
            <div className="flex-footer">
                <Footer />
            </div>
            <SiteToaster />
        </div>
    );
}

export default App;

const SiteToaster = () => {
    return (
        <Toaster
            position="top-right"
            containerStyle={{ top: '90px' }}
            toastOptions={{
                duration: 5000,
                style: {
                    borderRadius: '5px',
                    borderLeft: '5px solid #9e0442',
                    wordBreak: 'break-word',
                    maxWidth: '600px',
                },
                // Default options for specific types
                success: {
                    duration: 3000,
                    style: {
                        borderLeftColor: '#3daf8d',
                    },
                    iconTheme: {
                        primary: '#3daf8d',
                    },
                },
                error: {
                    duration: Infinity,
                    iconTheme: {
                        primary: '#9e0442',
                    },
                },
            }}
        >
            {(t) => (
                <ToastBar toast={t}>
                    {({ icon, message }) => (
                        <>
                            {icon}
                            {message}
                            {t.type !== 'loading' && (
                                <button className="btn btn-link text-gray-600 m-0 p-0" onClick={() => toast.dismiss(t.id)}>
                                    <BsX style={{ fontSize: '1.5em' }} />
                                </button>
                            )}
                        </>
                    )}
                </ToastBar>
            )}
        </Toaster>
    );
}