import classNames from 'classnames';
import { useState } from 'react';
import { get, useController } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';

// react-hook-form only sets errors when validation runs based on mode / reValidateMode
// if we want to show positive state after, need to use error along with touchedFields and/or isSubmitted

const getValidationStateClassName = (error) => (error ? 'is-invalid' : undefined);

export const ErrorFeedback = ({ error }) =>
  error ? (
    <div role="alert" className="invalid-feedback">
      {error.message}
    </div>
  ) : null;

export const TextField = ({ register, errors, name, className, label, labelClassName, ...props }) => {
    const error = get(errors, name);
    return (
        <>
            {label && (
                <label htmlFor={name} className={classNames('form-label', labelClassName)}>
                    {label}
                </label>
            )}
            <input
                type="text"
                className={classNames('form-control', className, getValidationStateClassName(error))}
                aria-invalid={!!error}
                id={name}
                {...register(name)}
                {...props}
            />
            <ErrorFeedback error={error} />
        </>
    );
}

export const TextAreaField = ({ register, errors, name, className, label, labelClassName, ...props }) => {
    const error = get(errors, name);

    return (
        <>
            {label && (
                <label htmlFor={name} className={classNames('form-label', labelClassName)}>
                    {label}
                </label>
            )}
            <textarea
                type="text"
                className={classNames('form-control', className, getValidationStateClassName(error))}
                aria-invalid={!!error}
                id={name}
                {...register(name)}
                {...props}
            ></textarea>
            <ErrorFeedback error={error} />
        </>
    );
};

export const NumberFormatField = ({ control, errors, name, label, labelClassName, className, setAsFormatted, ...props }) => {
    const error = get(errors, name);
    const { field } = useController({ control, name });
    return (
        <>
            {label && (
                <label htmlFor={name} className={classNames('form-label', labelClassName)}>
                    {label}
                </label>
            )}
            <PatternFormat 
                id={name}
                {...props}
                aria-invalid={!!error}
                className={classNames('form-control', className, getValidationStateClassName(error))}
                value={field.value}
                onValueChange={(values) => {
                    const setAsValue = setAsFormatted ? values.formattedValue : values.value;
                    if (setAsValue !== field.value) {
                        field.onChange(setAsValue);
                    }
                }}
            />
            <ErrorFeedback error={error} />
        </>
    );
};

export const SelectField = ({ register, errors, name, className, label, labelClassName, children, ...props }) => {
    const error = get(errors, name);
    return (
        <>
            {label && (
                <label htmlFor={name} className={classNames('form-label', labelClassName)}>
                    {label}
                </label>
            )}
            <select
                className={classNames('form-select', className, getValidationStateClassName(error))}
                aria-invalid={!!error}
                id={name}
                {...register(name)}
                {...props}
            >
                {children}
            </select>
            <ErrorFeedback error={error} />
        </>
    );
};

export const CheckboxField = ({
  register,
  registrationOptions,
  errors,
  name,
  className,
  wrapperClassName,
  children,
  ...props
}) => {
  const error = get(errors, name);
  return (
    <div className={classNames('form-check', wrapperClassName)}>
      <input
        type="checkbox"
        className={classNames('form-check-input', className, getValidationStateClassName(error))}
        aria-invalid={!!error}
        id={name}
        {...register(name, registrationOptions)}
        {...props}
      />
      <ErrorFeedback error={error} />
      {children}
    </div>
  );
};

export const RadioField = ({
    register,
    registrationOptions,
    errors,
    name,
    className,
    wrapperClassName,
    children,
    ...props
}) => {
    const error = get(errors, name);
    return (
        <div className={classNames('form-check', wrapperClassName)}>
            <input
                type="radio"
                className={classNames('form-check-input', className, getValidationStateClassName(error))}
                id={name}
                {...register(name, registrationOptions)}
                {...props}
            />
            <ErrorFeedback error={error} />
            {children}
        </div>
    );
};

export const CheckboxArray = ({ options /* {id: int, label} */, control, errors, name, className, labelClassName }) => {
  const valueAsNumber = typeof options[0].id === 'number';
  const error = get(errors, name);
  const { field } = useController({ control, name });
  const [value, setValue] = useState(field.value || []);
  const onChange = (e) => {
    const parsed = valueAsNumber ? Number(e.target.value) : e.target.value;
    const updated = e.target.checked ? [...value, parsed] : value.filter((v) => v !== parsed);
    field.onChange(updated);
    setValue(updated);
  };

  return (
    <>
      {options.map((option) => {
        const id = `${name}__${option.id}`;
        return (
          <div className="form-check" key={option.id}>
            <input
              type="checkbox"
              className={classNames('form-check-input ps-1', className)}
              value={option.id}
              checked={value.includes(option.id)}
              onChange={onChange}
              id={id}
            />
            <label htmlFor={id} className={classNames('form-check-label', labelClassName)}>
              {option.label}
            </label>
          </div>
        );
      })}
      <ErrorFeedback error={error} />
    </>
  );
};
