import React, { useEffect, useState } from 'react';
import { STATES } from '../../../constants';
import { NumberFormatField, SelectField, TextField } from '../../shared/FormElements';
import { useFormContext } from "react-hook-form";
import { getExams, getLicenseTypes } from '../../../ajax/appAjax';


export const ApplicantInfo = ({ children }) => {

    const { register, control, getValues, watch, setValue, formState: { errors } } = useFormContext();
    const [licenseTypes, setLicenseTypes] = useState([]);
    const [exams, setExams] = useState([]);

    const watchLicenseTypeId = watch('licenseTypeId');

    useEffect(() => {
        const exec = async () => {

            const l = await getLicenseTypes(getValues('clientAppTypeId'))
            const e = await getExams();
            setLicenseTypes(l);
            setExams(e);
        }

        exec();

    }, [getValues, setLicenseTypes, setExams]);


    useEffect(() => {

        if (watchLicenseTypeId) {
            const item = licenseTypes.find(s => s.licenseTypeId === parseInt(watchLicenseTypeId))
            setValue('fee', item.fee);
        } else {
            setValue('fee', 0);
        }


    }, [watchLicenseTypeId, setValue, licenseTypes])

    return <>
        <h4 className="pb-3">Applicant Information</h4>
        <div className="border rounded p-4">
            <div className="row">
                <div className="col-sm-6 mb-4">
                    <SelectField name="licenseTypeId" label="License Type Applying For" register={register} errors={errors} labelClassName="required">
                        <option value="">-- Choose --</option>
                        {licenseTypes.map(x => <option key={`lt-${x.licenseTypeId}`} value={x.licenseTypeId} >{x.name} (${x.fee})</option>)}
                    </SelectField>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 mb-4">
                    <SelectField name="examId" label="Exam" register={register} errors={errors} labelClassName="required">
                        <option value="">-- Choose --</option>
                        {exams.filter(x => x.licenseTypeId === parseInt(watchLicenseTypeId))
                            .map(x => <option key={`e-${x.examId}`} value={x.examId} >{x.name}</option>)}
                    </SelectField>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 mb-4">
                    <TextField name="schoolCode" label="School Code" register={register} errors={errors} labelClassName="required" />
                </div>
            </div>
            <div className="row">
                <div className="col-8 col-lg-4 mb-4">
                    <TextField name="firstName" label="First Name" register={register} errors={errors} labelClassName="required" />
                </div>
                <div className="col-4 col-lg-2 mb-4">
                    <TextField name="middleInitial" label="Middle Initial" register={register} errors={errors} />
                </div>
                <div className="col-lg-6 mb-4">
                    <TextField name="lastName" label="Last Name" register={register} errors={errors} labelClassName="required" />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 col-lg-5 mb-4">
                    <TextField name="residenceAddress" label="Residence Street Address" register={register} errors={errors} labelClassName="required" />
                </div>
                <div className="col-sm-6 col-lg-3 mb-4">
                    <TextField name="residenceCity" label="City" register={register} errors={errors} labelClassName="required" />
                </div>
                <div className="col-sm-6 col-lg-2 mb-4">
                    <SelectField name="residenceState" label="State" register={register} errors={errors} labelClassName="required">
                        <option value="">-- Choose --</option>
                        {STATES.map(x => <option key={`rs-${x.code}`} value={x.code} >{x.name}</option>)}
                    </SelectField>
                </div>
                <div className="col-sm-6 col-lg-2 mb-4">
                    <NumberFormatField
                        name="residenceZip"
                        label="Zip/Postal Code"
                        errors={errors}
                        labelClassName="required"
                        valueIsNumericString
                        format="#####"
                        control={control}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 col-lg-3 mb-4">
                    <NumberFormatField
                        name="telephone"
                        label="Phone Number"
                        errors={errors}
                        labelClassName="required"
                        valueIsNumericString
                        format="###-###-####"
                        control={control}
                    />
                </div>
                <div className="col-sm-6 col-lg-3 mb-4">
                    <TextField type="email" name="email" label="Email" register={register} errors={errors} labelClassName="required" />
                </div>
                <div className="col-sm-6 col-lg-3 mb-4">
                    <NumberFormatField
                        name="ssn"
                        label="Social Security Number"
                        errors={errors}
                        labelClassName="required"
                        valueIsNumericString
                        format="###-##-####"
                        control={control}
                    />
                </div>
                <div className="col-sm-6 col-lg-3 mb-4">
                    <TextField type="date" name="dateOfBirth" label="Date of Birth" register={register} errors={errors} labelClassName="required" />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 col-lg-5 mb-4">
                    <TextField name="mailingAddress" label="Mailing Address (If Different)" register={register} errors={errors} />
                </div>
                <div className="col-sm-6 col-lg-3 mb-4">
                    <TextField name="mailingCity" label="City" register={register} errors={errors} />
                </div>
                <div className="col-sm-6 col-lg-2 mb-4">
                    <SelectField name="mailingState" label="State" register={register} errors={errors}>
                        <option value="">-- Choose --</option>
                        {STATES.map(x => <option key={`ms-${x.code}`} value={x.code} >{x.name}</option>)}
                    </SelectField>
                </div>
                <div className="col-sm-6 col-lg-2 mb-4">
                    <NumberFormatField
                        name="mailingZip"
                        label="Zip/Postal Code"
                        errors={errors}
                        valueIsNumericString
                        format="#####"
                        control={control}
                    />
                </div>
            </div>
            {children}
        </div>
    </>
};

export default ApplicantInfo;