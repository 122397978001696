import React from "react";
import ApplicationPage from './applications/ApplicationsPage';
import ApplicationDetail from './applications/ApplicationDetail';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from '../../utils/authConfig';
import { Route, Routes, Navigate } from "react-router-dom";
import Authorizer from "./Authorizer";

const pca = new PublicClientApplication(msalConfig);

const Admin = () => {

    return <div className="container py-4 py-sm-5">
        <div className="row p-3 shadow-sm rounded bg-white">
            <div className="col-12 py-3">
                <MsalProvider instance={pca}>
                    <Authorizer>
                        <Routes>
                            <Route path="applications" element={<ApplicationPage />} />
                            <Route path="applications/:applicationId" element={<ApplicationDetail />} />
                            <Route path="*" element={<Navigate replace to="/admin/applications" />} />
                        </Routes>
                    </Authorizer>
                </MsalProvider>
            </div>
        </div>
    </div>
}


export default Admin;