import React, { useState, useEffect, useMemo } from "react";
import { Link } from 'react-router-dom';
import { TableWithGlobalSearch } from "../../shared/Table";
import { getApplications } from "../../../ajax/adminAjax";
import { formatDateFromISOtoLocal, formatDateFromISO, FormatPhone } from "../../../utils/formatters";
import { useToggle } from "../../../utils/hooks";

const useColumns = () => {

    const columns = useMemo(() => [{
        header: 'Test Taker',
        cell: ({
            row: {
                original: { applicationId, firstName, lastName },
            },
        }) => <Link className="text-underline" to={`/admin/applications/${applicationId}`}>{`${firstName} ${lastName}`}</Link>,
        accessorFn: row => `${row.firstName} ${row.lastName}`,
    },
        {
            header: 'App Date',
            cell: ({
                row: {
                    original: { createdOn },
                },
            }) => <span>{formatDateFromISOtoLocal(createdOn)}</span>
        },
        {
            header: 'License',
            cell: ({
                row: {
                    original: { licenseType },
                },
            }) => <span>{licenseType}</span>,
            accessorFn: row => row.clientAppTypeId === 1 ? '' : row.licenseType,
        },
        {
            header: 'Exam',
            accessorKey: 'examName'
        },
        {
            header: 'Phone',
            cell: ({
                row: {
                    original: { telephone },
                },
            }) => <FormatPhone phone={telephone} />,
        },
        {
            header: 'Export Date',
            cell: ({
                row: {
                    original: { exportedOn },
                },
            }) => <span>{formatDateFromISO(exportedOn)}</span>
        },
    ], []);

    return columns;
};


const IncludeExportedCheck = ({ setIncludeExported, includeExported }) => <>
    <div className="form-check form-check-inline pt-2">
        <input id="activeOnlyCheck" className="form-check-input" type="checkbox" checked={includeExported} onChange={setIncludeExported} />
        <label className="form-check-label" htmlFor="activeOnlyCheck" >Include Exported</label>
    </div>
</>


const ExportPage = () => {

    const [data, setData] = useState([]);
    const columns = useColumns();
    const [includeExported, setIncludeExported] = useToggle();

    useEffect(() => {
        const exec = async () => {
            const apps = await getApplications(includeExported);
            setData(apps);
        }

        exec();

    }, [setData, includeExported]);


    return <>
        <div>
            <TableWithGlobalSearch
                title="Applications"
                tableClassName="table-striped"
                columns={columns}
                data={data}
                extraBtn={<IncludeExportedCheck
                    setIncludeExported={setIncludeExported}
                    includeExported={includeExported}
                />}
                withSortBy
                withPagination
            />
        </div>
    </>
}


export default ExportPage;