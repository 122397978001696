import { useState } from 'react';
import classNames from 'classnames';
import * as yup from 'yup';
import { DateTime } from "luxon";
import valid from 'card-validator';
import { useForm, Controller, FormProvider, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { saveApplicaton } from '../../../ajax/appAjax';
import { ErrorFeedback } from '../../shared/FormElements';
import { SpinnerSm } from '../../shared/Loading';

const getValidationStateClassName = (error) => (error ? 'is-invalid' : undefined);

const mailingRequired = (field1, field2, field3) =>
	field1  || field2  || field3 ;


export const validationSchema = yup
	.object()
	.shape({
		licenseTypeId: yup.string().required('License Type is required'),
		examId: yup.string().required('Exam is required'),
		schoolCode: yup.string().required('School code is required'),
		middleInitial: yup.string().trim().uppercase().notRequired(),
		firstName: yup.string().required('First Name is required'),
		lastName: yup.string().required('Last Name is required'),
		ssn: yup.string().trim().required('Social Security Number is required'),
		telephone: yup.string().trim().required('Telephone is required'),
		email: yup.string().trim().required('Email is required').email('Not a valid email'),
		dateOfBirth: yup.date().required('DOB is required')
			.max(new Date(), 'DOB cannot be in the future')
			.typeError('DOB must be a valid date')/*.min(new Date(1900, 0, 1))*/,
		residenceAddress: yup.string().trim().required('Street Address is required'),
		residenceCity: yup.string().trim().required('City is required'),
		residenceState: yup.string().trim().required('State is required'),
		residenceZip: yup.string().trim().required('Postal Code is required'),
		billingAddress: yup.string().trim().required('Street Address is required'),
		billingCity: yup.string().trim().required('City is required'),
		billingState: yup.string().trim().required('State is required'),
		billingZip: yup.string().trim().required('Postal is required'),
		cardholder: yup.string().trim().required('Cardholder is required'),
		cardNumber: yup.string().trim().required('Credit Card number is required')
			.test('test-number', 'Credit Card Number is invalid',
				value => valid.number(value).isValid),
		expiration: yup.string().trim().required('Expiration is required')
			.test('test-expiration', 'Expiration is invalid',
				value => valid.expirationDate(value).isValid),
		cvv: yup.string().trim().required('CVV is required')
			.test('test-cvv', 'CVV is invalid',
				value => valid.cvv(value).isValid),
		mailingAddress: yup.string().trim().when(['mailingCity', 'mailingState', 'mailingZip'], {
			is: mailingRequired,
			then: schema => schema.required('Street Address is required'),
			otherwise: schema => schema.notRequired()
		}),
		mailingCity: yup.string().trim().when(['mailingAddress', 'mailingState', 'mailingZip'], {
			is: mailingRequired,
			then: schema => schema.required('City is required'),
			otherwise: schema => schema.notRequired()
		}),
		mailingState: yup.string().trim().when(['mailingAddress', 'mailingCity', 'mailingZip'], {
			is: mailingRequired,
			then: schema => schema.required('State is required'),
			otherwise: schema => schema.notRequired()
		}),
		mailingZip: yup.string().trim().when(['mailingAddress', 'mailingCity', 'mailingState'], {
			is: mailingRequired,
			then: schema => schema.required('Postal Code is required').length(5),
			otherwise: schema => schema.notRequired()
		}),
		convictedOfFelony: yup.boolean().required('Must select Yes or No').nullable(),
		convictedOfCrime: yup.boolean().required('Must select Yes or No').nullable(),
		completedCoursework: yup.boolean().when('licenseTypeId', (lt, schema) => {
			return parseInt(lt) === 2 ? schema.required('Must select Yes or No').nullable() : schema.notRequired().nullable();
		}),
		licenseNumber: yup.string().when('licenseTypeId', (lt, schema) => {
			return parseInt(lt) === 2 ? schema.required('License Number is required') : schema.notRequired();
		}),
		militaryService: yup.boolean().when('clientAppTypeId', (appId, schema) => {
			return appId === 2 ? schema.required('Must select Yes or No').nullable() : schema.notRequired().nullable();
		}),
		licenseSuspenedRevoked: yup.boolean().when('clientAppTypeId', (appId, schema) => {
			return appId === 1 ? schema.required('Must select Yes or No').nullable() : schema.notRequired().nullable();
		}),
		presentlyHoldLicense: yup.boolean().when('clientAppTypeId', (appId, schema) => {
			return appId === 2 ? schema.required('Must select Yes or No').nullable() : schema.notRequired().nullable();
		}),
		felonyFile: yup.mixed().when(['convictedOfFelony'], {

			is: (val) => val === true,
			then: schema => schema.test("felonyFile", 'Criminal Conviction Application Worksheet is required', (felonyFile) => {
				if (felonyFile && felonyFile.length > 0) return true;
				return false;
			}), 
			otherwise: schema => schema.notRequired()
		}),
		currentLicenseUpload: yup.mixed().when(['presentlyHoldLicense'], {

			is: (val) => val === true,
			then: schema => schema.test("file", 'Current License Required', (file) => {
				if (file && file.length > 0) return true;
				return false;
			}),
			otherwise: schema => schema.notRequired()
		}),
		revokedDetails: yup.mixed().when(['licenseSuspenedRevoked'], {

			is: (val) => val === true,
			then: schema => schema.required('Details Required'),
			otherwise: schema => schema.notRequired()
		}),
	}, [
		['mailingAddress', 'mailingZip'], ['mailingCity', 'mailingZip'], ['mailingState', 'mailingZip'],
		['mailingAddress', 'mailingCity'], ['mailingState', 'mailingCity'], ['mailingZip', 'mailingCity'],
		['mailingAddress', 'mailingState'], ['mailingCity', 'mailingState'], ['mailingZip', 'mailingState'],
		['mailingCity', 'mailingAddress'], ['mailingCity', 'mailingAddress'], ['mailingZip', 'mailingAddress']
	]);



const defaultValues = {
	firstName: '',
	lastName: '',
	middleInitial: '',
	ssn: '',
	telephone: '',
	email: '',
	dateOfBirth: undefined,
	clientAppTypeId: '',
	licenseTypeId: '',
	examId: '',
	schoolCode: '',
	licenseSuspenedRevoked: null,
	convictedOfFelony: null,
	convictedOfCrime: null,
	presentlyHoldLicense: null,
	militaryService: null,
	residenceAddress: '',
	residenceCity: '',
	residenceState: '',
	residenceZip: '',
	mailingAddress: '',
	mailingCity: '',
	mailingState: '',
	mailingZip: '',
	billingAddress: '',
	billingCity: '',
	billingState: '',
	billingZip: '',
	signStatement: false,
	cardholder:'',
	cardNumber: '',
	expiration: '',
	licenseNumber: '',
	cvv: '',
	fee: 0,
	revokedDetails: '',
	felonyFile: undefined,
	currentLicenseUpload: undefined,
	fileUploads: []
}

export const ApplicationForm = ({ clientAppTypeId, children }) => {

	const navigate = useNavigate();

	const [working, setWorking] = useState(false);


	const methods = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			...defaultValues,
			clientAppTypeId,
			militaryService: clientAppTypeId === 2 ? null : false,
			licenseSuspenedRevoked: clientAppTypeId === 1 ? null : false,
			presentlyHoldLicense: clientAppTypeId === 2 ? null : false,
		}
	});

	const submit = useCallback(async (vals) => {

		setWorking(true);
		try {
			vals.dateOfBirth = DateTime.fromJSDate(vals.dateOfBirth).toFormat('yyyy-MM-dd')
			await saveApplicaton(vals);
			toast.success('Application saved');
			navigate('/thank-you')
		} finally {
			setWorking(false);
		}

	}, [setWorking, navigate]);

	const watchSignStatement = methods.watch('signStatement');

	return (
		<FormProvider {...methods} >
			<form onSubmit={methods.handleSubmit(submit)} autoComplete="off">
				<div className="row p-3 shadow-sm rounded bg-white">
					<div className="col-12 py-3">
						{children}
						<div className="row">
							<div className="col-12 mt-4 text-end">
								<button className="btn btn-secondary" type="submit" disabled={working || !watchSignStatement}>
									{working && <SpinnerSm />} Submit
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</FormProvider>
	);


}

export default ApplicationForm;


export const TrueFalseRadioButtons = ({ control, name}) => {


	return <Controller
      control={control}
      name={name}
		render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
		  	<>
				<div className={classNames('form-check form-check-inline', getValidationStateClassName(error))}>
					<input
						className="form-check-input"
						type="radio"
						onBlur={onBlur} // notify when input is touched
						onChange={() => onChange(true)} // send value to hook form
						checked={value === true}
						id={`${name}Yes`}
					/>
					<label className="form-check-label" htmlFor={`${name}Yes`}>Yes</label>
			</div>
				<div className="form-check form-check-inline">
					<input
						className="form-check-input"
						type="radio"
						onBlur={onBlur} // notify when input is touched
						onChange={() => onChange(false)} // send value to hook form
						checked={value === false}
						id={`${name}No`}
					/>
					<label className="form-check-label" htmlFor={`${name}No`}>No</label>
			</div>
			<ErrorFeedback error={error} />
        </>
      )}
    />

}

export const FileUploadInput = ({ control, name, register, ariaDescribedBy }) => {

	return <Controller
		control={control}
		name={name}
		render={({ fieldState: { error } }) => {

			return <>
				<input
					type="file"
					className={classNames('form-control', getValidationStateClassName(error))}
					{...register(name)}
					aria-describedby={ariaDescribedBy}
				/>

				<ErrorFeedback error={error} />
			</>

		}}
	/>

}

