import React, { useState, useEffect, useCallback } from "react";
import { getApplication, markExported } from "../../../ajax/adminAjax";
import { formatDateFromISO, formatDateFromISOtoLocal  } from "../../../utils/formatters";
import { Spinner } from "../../shared/Loading";
import { useNavigate } from 'react-router-dom';
import {  useParams } from 'react-router-dom';
import { PatternFormat } from 'react-number-format';
import { BiCopy } from 'react-icons/bi';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { download } from "../../../ajax/authHandler";
import toast from 'react-hot-toast';
import classNames from 'classnames';



const ApplicationDetail = () => {

    const { applicationId } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const navigate = useNavigate();
    const [, copy] = useCopyToClipboard();

    const markAsExported = useCallback(async () => {
        await markExported(applicationId);
        toast.success('Application Imported');
        navigate('/admin');
    }, [applicationId, navigate])

    

    useEffect(() => {

        const exec = async () => {
            try {
                const app = await getApplication(applicationId);
                setData(app);
            } finally {
                setLoading(false);
            }
        }

        exec();


    }, [setData, applicationId]);

    if (loading) {
        return <Spinner />
    }


    return <>
        {data.exportedOn && <div className="alert alert-info print-hide" role="alert">
            <BsFillInfoCircleFill className="me-2" /> This application was marked as exported on {formatDateFromISO(data.exportedOn) }
        </div>}
        <h4 className="pb-3 px-2">Application</h4>
        <div className="row g-5">
            <div className="col-lg-6">
                <table className="review-table">
                    <tbody>
                        <FormRow label="App Date" value={formatDateFromISOtoLocal(data.createdOn)} />
                        <FormRow label="App Type" value={data.clientAppType} />
                        <FormRow label="License" value={data.licenseType} />
                        <FormRow label="Exam" value={data.examName} />
                        <FormRow label="School code" value={data.schoolCode} />
                    </tbody>
                </table>
            </div>
        </div>

        <h4 className="pt-5 pb-3 px-2">Applicant Information</h4>
        <div className="row g-5">
            <div className="col-lg-6">
                <table className="review-table">
                    <tbody>
                        <FormRow label="First Name" value={data.firstName} />
                        <FormRow label="Last Name" value={data.lastName} />
                        <FormRowPattern trClassName="noprint" label="SSN"
                            value={data.ssn}
                            pattern="###-##-####"
                            printValue={data.ssn.substr(-4)}
                            printPattern="XXX-XX-####"
                        />
                    </tbody>
                </table>
            </div>
            <div className="col-lg-6">
                <table className="review-table">
                    <tbody>
                        <FormRow label="DOB" value={formatDateFromISO(data.dateOfBirth)} />
                        <FormRowPattern label="Phone" value={data.telephone} pattern="###-###-####" />
                        <FormRow label="Email" value={data.email} />
                    </tbody>
                </table>
            </div>
        </div>
        <div className="row pt-4 break-before" >
            <div className="col-12">
                {data.licenseTypeId === 2 && <><div className="px-2">
                    <p className="mb-1">I acknowledge that I have completed the required coursework listed above and have been activily engaged for at least three (3) years as a licensed real estate salesperson under the supervision of a licensed real estate broker in this state</p>
                    <p className="mb-0">
                        {data.completedCoursework && <span>Yes</span>}
                        {!data.completedCoursework && <span>No</span>}
                        <br />
                        License Number : {data.licenseNumber}<button className="print-hide btn btn-link p-0" onClick={() => copy(data.licenseNumber)}><BiCopy className="print-hide"/></button>

                    </p>
                </div>
                <hr className="mt-3" />
                </>}
                <div className="px-2">
                    <p className="mb-1">Have you ever been convicted of a felony?</p>
                    <p className="mb-0">
                        {data.convictedOfFelony && <span>Yes</span>}
                        {!data.convictedOfFelony && <span>No</span>}
                    </p>
                </div>
                <hr className="mt-3" />
                <div className="px-2">
                    <p className="mb-1">Have you ever been convicted of a felony crime or been convicted of a crime including, but not limited to, forgery, embezzlement,
                        obtaining money under false pretenses, extortion, criminal conspiracy to defraud, or any like offenses?</p>
                    <p className="mb-0">
                        {data.convictedOfCrime && <span>Yes</span>}
                        {!data.convictedOfCrime && <span>No</span>}
                    </p>
                </div>
                <hr className="mt-3" />
                <div className="px-2">
                    <p className="mb-1">Have you ever had a real estate license refused, suspended or revoked in any State?</p>
                    <p className="mb-0">
                        {data.licenseSuspenedRevoked && <span>Yes</span>}
                        {!data.licenseSuspenedRevoked && <span>No</span>}
                    </p>
                    {data.licenseSuspenedRevoked && <p className="mt-1 mb-0">
                        {data.revokedDetails}
                    </p>}
                </div>

            </div>
        </div>

        <h4 className="pt-5 pb-3 px-2">Addresses</h4>

        <div className="row g-5">
            {data.addresses.filter(x => x.applicationAddressTypeId === 1 || x.applicationAddressTypeId === 2).map(a => <Address key={`ad-${a.applicationAddressTypeId}`} address={a} />)}
        </div>

        <Card card={data} />

        <div className="print-hide">
        
            <h4 className="pt-4 pb-3 px-2">Attachments</h4>
            <div className="row pl-5 mb-4" >
                <div className="col-lg-6">
                    {data.files.map((f, i) => <div key={`file-${i}`}><button className="btn btn-link" onClick={() => download(`/api/admin/file/${f.applicationFileId}`, f.fileName)}>{f.fileName}</button></div>)}
                </div>
            </div>
            <div>
                <button type="button" className="btn btn-primary mx-2 mb-4" onClick={markAsExported}>Mark as Imported</button>

                <button type="button" className="btn btn-primary mx-2 mb-4" onClick={() => window.print()}>Print</button>
            </div>
            
        </div>
    </>
}


export default ApplicationDetail;

const Card = ({ card }) => {


    const billingAddress = card.addresses.find(x => x.applicationAddressTypeId === 3);

    return <>
          <h4 className="pt-5 pb-3 px-2 break-before">Payment Information</h4>
          <div className="row g-5">
            <div className="col-lg-6">
              <p className="px-2 mb-2 text-primary fs-5 fst-italic">Credit Card</p>
              <table className="review-table">
                    <tbody>
                        <FormRowPattern label="Card Number"
                            value={card.cardNumber}
                            pattern="#### #### #### ####"
                            printPattern="XXXX XXXX XXXX ####"
                            printValue={card.cardNumber.substr(-4)}
                        />
                        <FormRowPattern label="Expiration" value={card.expiration} pattern="##/##" />
                        <FormRow label="CVV" value={card.cvv} className="print-hide" />
                </tbody>
              </table>
            </div>
            <Address address={billingAddress} />
       </div>
    </>
}

const Address = ({ address }) => {

    return <div className="col-lg-6">
            <p className="px-2 mb-2 text-primary fs-5 fst-italic">{address.addressType}</p>
            <table className="review-table">
                <tbody>
                    <FormRow label="Street Address" value={address.address} />
                    <FormRow label="City" value={address.city} />
                    <FormRow label="State" value={address.state} />
                    <FormRow label="Zip/Postal Code" value={address.zip} />
                </tbody>
            </table>
        </div>
}


const useCopyToClipboard = ()  => {
    const [copiedText, setCopiedText] = useState(null);

    const copy = async text => {
        if (!navigator || !navigator.clipboard) {
            console.warn('Clipboard not supported')
            return false
        }

        // Try to save to clipboard then save it in the state if worked
        try {
            await navigator.clipboard.writeText(text)
            setCopiedText(text)
            toast.success('Copied!')
            return true
        } catch (error) {
            console.warn('Copy failed', error)
            setCopiedText(null)
            return false
        }
    }

    return [copiedText, copy]
}


const FormRow = ({ label, value, className }) => {

    const [, copy] = useCopyToClipboard();

    return <tr className={className}>
        <th>{label}:</th>
        <td>{value}</td>
        <td className="icon-td print-hide"><button className="btn btn-link p-0" onClick={() => copy(value)}><BiCopy /></button></td>
    </tr>
}




const FormRowPattern = ({ label, value, pattern, printValue, printPattern}) => {

    const [, copy] = useCopyToClipboard();

    return <tr>
        <th>{label}:</th>
        <td>
            <PatternFormat
                format={pattern}
                value={value}
                displayType="text"
                className={classNames({ 'print-hide': !!printValue })}
            />
            {printValue && <PatternFormat
                format={printPattern}
                value={printValue}
                displayType="text"
                className="print-show"
            />}
           </td>
        <td className="icon-td print-hide"><button className="btn btn-link p-0" onClick={() => copy(value)}><BiCopy /></button></td>
    </tr>

}
