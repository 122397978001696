
const url = `https://${window.location.hostname}`;


export const msalConfig = Object.freeze({
    auth: {
        clientId: window.__APP_CONFIG__.clientId,
        authority: window.__APP_CONFIG__.authority,
        redirectUri: url + '/admin'
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    },
});

export const TOKEN_REQUEST = {
    scopes: [window.__APP_CONFIG__.scope]
};

export const REQUEST_SCOPES = {
    scopes: ["openid", "profile", window.__APP_CONFIG__.scope]
};