import { useCallback, useEffect, useState, useRef } from 'react';
import { Modal } from 'bootstrap';


// simple case
// initializes boostrap Modal when the ref is attached so data-bs-toggle/data-bs-target/data-bs-dismiss just work
// usage:
//  const myModalRef = useModalCallbackRef();
//  <button type="button" data-bs-toggle="modal" data-bs-target="#myModal">Open</button>
//
//  <div className="modal fade" tabIndex="-1" id="myModal" ref={myModalRef}>
//  <button type="button" data-bs-dismiss="modal">Close</button>
export const useModalCallbackRef = (options) => {
    const optionsRef = useRef(options);
    return useCallback((node) => new Modal(node, optionsRef.current), []);
};

// initializes boostrap Modal and returns the modal instance so modal.hide(), modal.show() work
// usage:
//  const [myModal, myModelRef] = useModalRef();
//  <button type="button" onClick={() => modal.open()}>Open</button>
//
//  <div className="modal fade" tabIndex="-1" ref={myModalRef}>
//  <button type="button" onClick={() => modal.hide()}>Close</button>
export const useModalInstance = (options) => {
    const optionsRef = useRef(options || {});
    const [modal, setModal] = useState();
    const modalRef = useRef();

    useEffect(() => setModal(new Modal(modalRef.current, optionsRef.current)), []);

    return [modal, modalRef];
};

export const useToggle = (initial = false) => {

    const [value, setValue] = useState(initial);

    const toggleValue = useCallback((v) => {
        if (v === true) setValue(true);
        else if (v === false) setValue(false);
        else setValue(c => !c)
    }, []);

    return [value, toggleValue];
}

//https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
        callback(); // fire right away
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}