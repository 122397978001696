import React from 'react';
import { Link } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';

export const PageContainer = ({ children, title }) => {

    return <>
        <div className="container py-4 py-sm-5">
            <div className="row pb-4 px-3 px-md-0">
                <div className="col mb-3 mb-lg-0">
                    <Link to="/" className="btn btn-link text-gray-600 p-0 mb-3"><FaChevronLeft className="me-1" />Back</Link>
                    <div className="row">
                        <div className="col">
                            <h3>{title}</h3>
                        </div>
                    </div>
                </div>
            </div>
            {children}
        </div>
    </>
}

export default PageContainer;